/*CSS for handling Cart page without product*/
.noitem{
    padding: 70px 0px;
}

.noitem Button{
    font-size: 20px;
    margin-top: 20px;
    letter-spacing: 0.025em;
    width: 220px;
    height: 45px;
    border-radius: 7px;
    background-color: #414141;
    color: white;
    font-weight: 600;
    border: none;
}

.noitem h1{
    padding-top: 80px;
}

.noitem Button:hover{
    background-color: #5A5A5A;
}

/*CSS for handling Cart page with product */
.cartheader{
    padding: 30px 0px 10px 0px !important;
    text-transform: uppercase;
    font-size: 19px;
    letter-spacing: 0.03em;
    max-width: 85%;
    margin: auto !important; 
}

.cartproduct{
    border-top: 2px black solid;
    padding: 18px 0px !important;
    max-width: 85%;
    margin: auto !important; 
    align-items: center;
}

.cartproduct img{
    width: 130px;
}

.cartdetail p{
    margin: 0px !important;
}

.removeItem:hover{
    text-decoration: underline;
    cursor: pointer
}

.updateQuantityDiv{
    max-width: 57%;
    margin: auto !important;
    border: 2px solid black;
    height: 38px;
    align-items: center;
    justify-content: center;
}

.updateQuantityDiv h5{
    margin: 0px;
}

.cartTotal {
    align-items: center !important;
    max-width: 85%;
    margin: auto !important;
    border-top: 2px black solid;
}

.subtotal{
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-top: 21px !important;
}

.orderButton{
    border: none !important;
    background-color: #414141 !important;
    width: 150px;
    height: 42px;
    letter-spacing: 0.05em;
}

.orderButtondiv {
    max-width: 85%;
    margin: auto !important;
}

.input-quantity {
    border: 0;
    text-align: center;
    width: 100%;
}

@media only screen and (max-width: 576px) {
    .noitem{
        max-width: 90%;
        padding: 0px 0px;
        margin: auto;
    }

    .noitem h1{
        font-size: 23px;
    }

    .noitem h5{
        font-size: 15px;
    }

    .noitem Button{
        font-size: 15px;
        margin-top: 20px;
        letter-spacing: 0.025em;
        width: 160px;
        height: 40px;
        border-radius: 7px;
        background-color: #414141;
        color: white;
        font-weight: 600;
        border: none;
    }

    .updateQuantityDiv{
        max-width: 90%;
    }

    /*CSS for Filled Cart */
    .cartheader {
        font-size: 14px;
    }

    .cartproduct img{
        width: 80px;
    }

    .cartdetail p{
        font-size: 13px;
    }

    .cartdetail h6{
        max-width: 95%;
        font-size: 14px;
    }

    .updateQuantityDiv{
        max-width: 85%;
        height: 31px;
    }

    .updateQuantityDiv svg{
        font-size: 10px;
    }

    .updateQuantityDiv h5{
        font-size: 16px;
    }

    .itemPricing p{
        font-size: 15px;
        margin: 0;
    }

    .subtotal p {
        font-size: 15px;
    }
}