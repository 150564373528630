.navbar{
    padding: 10px 0px !important;
}

.navbar-nav {
    align-items: center !important;
}

.mainbrand > h3 {
    text-transform: uppercase;
    letter-spacing: 0.07em;
    margin-left: 20px;
    margin-bottom: 0px;
}

.nav-link {
    font-size: 15px;
    padding: 0.5rem 0.5rem !important;
    color: rgb(48, 48, 48) !important;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.nav-link:focus {
    color: black !important;
    text-decoration: underline;
}

.nav-icon {
    margin: 8px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 32px;
    color: darkslategray;
}

.inputlogo{
    height:20px !important;
    width: 20px !important;
}

.wa-button {
    display: flex;
    border: none !important;
    position: fixed;
    border-radius: 2px;
    background-color: #13990a !important;
    right: 8px;
    bottom: 45px;
    font-size: 13px;
    z-index: 1;
    cursor: pointer;
}

.wa-button:hover {
    background-color: #15880c !important;
}

.wa-button img{
    width:30px;
}

@media screen and (max-width: 560px) {
    .navbar-nav {
        align-items: start !important;
    }

    .mainbrand > h3 {
        padding-top: 0;
        padding-bottom: 0;
        margin-left: 0px;
        margin-bottom: 0px;
    }
}