address {
    margin-bottom: 0px !important;
}

th {
    font-weight: 700;
}

.invoice-container {
    color: #333;
    margin: 15px auto;
    padding: 20px 30px;
    height: 14cm;
    width: 21.5cm;
    background-color: #fff;
    border: 1px solid #ccc;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
    /* font-family: 'Times New Roman', Times, serif; */
    /* font-family: "Montserrat regular"; */
    font-family: Arial, Helvetica, sans-serif;
}

.invoice-col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.label {
    display: inline-block;
    width: 100px;
    text-align: right;
  }

.invoice-checkbox {
    height: 15px;
    width: 15px;
    border: 1px solid lightgrey;
    margin-right: auto;
    margin-left: auto;
}

/* Invoice Status Formatting */

.invoice-container .invoice-status {
    margin: 20px 0 0 0;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
}

/* Invoice Status Colors */

.draft {
    color: #888;
}
.unpaid {
    color: #cc0000;
}
.paid {
    color: #779500;
}
.refunded {
    color: #224488;
}
.cancelled {
    color: #888;
}
.collections {
    color: #ffcc00;
}

/* Payment Button Formatting */

.invoice-container .payment-btn-container {
    margin-top: 5px;
    text-align: center;
}
.invoice-container .payment-btn-container table {
    margin: 0 auto;
}

/* Text Formatting */

.small-text {
    font-size: 14px !important;
    font-weight: 700;
}

.name-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

td > .name-text {
    margin-top: 2px;
}

.invoice-tbody {
    font-size: 14px;
    font-weight: 700;
}

/* Invoice Items Table Formatting */

.items-table {
    width: 100%;
}

.checkbox-col {
    width: 24px;
    text-align: center;
}

.name-col {
    width: 340px;
    display: block;
}

.price-col {
    width: 128px;
}

td {
    padding-left: 8px;
    padding-right: 8px;
}

/* tr {
    border-bottom: 1px solid black!important;
} */

/* tr > td:nth-child(1) {
    border-right: 1px solid black;
}
tr > td:nth-child(3) {
    border-left: 1px solid black;
    border-right: 1px solid black;
}
tr > td:nth-child(4) {
    border-left: 1px solid black;
    border-right: 1px solid black;
} */

/* tr:nth-child(even) {background-color: #f2f2f2;} */

.items-table input[type="checkbox"] {
    /* width: 16px;
    height: 16px; */
    margin-top: 6px;
}

tr, td {
    height: 26px;
}

tfoot tr {
    font-size: 14px;
}

/*Autograph Row Formatting*/
.autograph-container {
    margin-top: 64px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.autograph-container > :nth-child(2) {
    margin-left: 70px;
  }
  
.autograph-line {
    width: 120px;
    height: 1px;
    background-color: black;
    margin-right: 0px;
}

.autograph-title {
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    text-align: center;
}

@media print {
    table, tbody, thead, tfoot, tr, td, th, div.row {
        page-break-inside: avoid;
    }
    .invoice-actions-container {
        display: none;
    }
    .invoice-container {
        page-break-inside: avoid;
        /* page-break-after: always; */
        margin-top: 0;
        margin-bottom: 0;
        border: 0;

        height: 13cm;
        width: 20cm;
        padding: 0;
        padding-top: 24px;
    }

    .price-col {
        color: black!important;
    }

    .badge {
        display: none!important;
    }


    .action-button {
        display: none;
    }

    .wa-button, .footerdiv, nav {
        display: none;
    }
}

.invoice-actions-container {
    text-align: right;
}

.action-button {
    color: white!important;
    width: 160px;
    letter-spacing: 0.03em;
    font-size: 18px !important;
    background-color: #414141 !important;
}

li {
    margin-top: 16px;
    margin-bottom: 16px;
}
