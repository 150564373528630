.formcontainer {
  max-width: 800px !important;
  margin: 0.5rem auto !important;
  padding: 1.25rem !important;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formheader h2 {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: none;
  letter-spacing: -0.5px;
}

.formGuide {
  color: #64748b;
  font-size: 1rem;
  margin: 0.75rem 0;
  font-weight: 400;
}

.form-group {
  margin-bottom: 1.5rem;
}

.formcol label {
  display: block;
  width: 100%;
  color: #334155;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-transform: none;
}

.formcol input,
.formcol select {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  border: 1.5px solid #e2e8f0;
  border-radius: 6px;
  background-color: #fff;
  transition: all 0.2s ease;
  height: auto;
}

.formcol input:focus,
.formcol select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.formcol input:hover,
.formcol select:hover {
  border-color: #94a3b8;
}

.form-select-container {
  position: relative;
}

.form-select-container select {
  width: 100%;
  padding-right: 2.5rem;
}

.select-search-container {
  position: sticky;
  top: 0;
  background: white;
  padding: 4px 8px;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 4px;
}

.select-search {
  width: 100%;
  padding: 4px 8px;
  font-size: 0.85rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  outline: none;
}

.select-search:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.customer-option {
  padding: 6px 12px;
  margin: 2px 0;
  cursor: pointer;
}

.customer-option:hover {
  background-color: #f8fafc;
}

/* Style for the select element to show search box */
.form-select[size] {
  padding-top: 0;
}

.form-select option {
  padding: 8px 12px;
  font-size: 0.9rem;
}

.form-select option:not(:first-child) {
  margin-top: 2px;
}

.invoicebutton {
  background-color: #3b82f6 !important;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 0.95rem !important;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.2s ease;
  margin: 0.75rem 0 0 0;
  width: auto;
  min-width: 140px;
}

.invoicebutton:hover:not(:disabled) {
  background-color: #2563eb !important;
  transform: translateY(-1px);
}

.invoicebutton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}

/* Error states */
.form-error {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.375rem;
}

.input-error {
  border-color: #ef4444 !important;
}

.input-error:focus {
  box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.1) !important;
}

/* Add spacing between form columns */
@media (min-width: 768px) {
  .form-row > [class^="col-"]:first-child {
    padding-right: 1rem;
  }
  .form-row > [class^="col-"]:last-child {
    padding-left: 1rem;
  }
}

/* Responsive design */
@media only screen and (max-width: 768px) {
  .formcontainer {
    max-width: 95% !important;
    padding: 1rem !important;
    margin: 0.5rem auto !important;
  }

  .formGuide {
    font-size: 0.95rem;
    margin: 0.5rem 0;
  }

  .formheader h2 {
    font-size: 1.25rem;
  }

  .formcol label {
    font-size: 0.85rem;
  }

  .formcol input,
  .formcol select {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
  }
}

.custom-select {
  position: relative;
  width: 100%;
}

.select-header {
  padding: 0.75rem 1rem;
  border: 1.5px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 2.5rem;
}

.select-header:hover {
  border-color: #94a3b8;
}

.placeholder {
  color: #94a3b8;
}

.select-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.search-container {
  padding: 8px;
  border-bottom: 1px solid #e2e8f0;
}

.search-container input {
  width: 100%;
  padding: 6px 12px;
  font-size: 0.85rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  outline: none;
}

.search-container input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.options-container {
  max-height: 250px;
  overflow-y: auto;
  padding: 4px 0;
}

.option {
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.option:hover {
  background-color: #f1f5f9;
}

.option-name {
  font-size: 0.9rem;
  color: #1e293b;
}

.option-phone {
  font-size: 0.8rem;
  color: #64748b;
  margin-top: 2px;
}

.no-options {
  padding: 8px 12px;
  color: #94a3b8;
  font-size: 0.85rem;
  text-align: center;
}