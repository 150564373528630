@font-face {
  font-family: "Montserrat Regular";
  src: url("./Fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("./Fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("./Fonts/Montserrat-Bold.ttf") format("truetype");
}

.fontRegular{
  font-family: "Montserrat Regular";
  color:black;
}

.fontMedium{
  font-family: "Montserrat Medium";
  color:black;
}

.fontBold{
  font-family: "Montserrat Bold";
  color:black;
}

.DMRegular{
  font-family: "DM Sans Regular";
  color:black;
}


body {
  margin: 0;
  padding:0;
  font-family: "Montserrat Regular";
}

div.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

div.row {
  margin-right: 0px;
  margin-left: 0px
}

div.col-lg-1,div.col-lg-10,div.col-lg-11,div.col-lg-12,div.col-lg-2,div.col-lg-3,div.col-lg-4,div.col-lg-5,div.col-lg-6,div.col-lg-7,div.col-lg-8,div.col-lg-9,
div.col-md-1,div.col-md-10,div.col-md-11,div.col-md-12,div.col-md-2,div.col-md-3,div.col-md-4,div.col-md-5,div.col-md-6,div.col-md-7,div.col-md-8,div.col-md-9,
div.col-sm-1,div.col-sm-10,div.col-sm-11,div.col-sm-12,div.col-sm-2,div.col-sm-3,div.col-sm-4,div.col-sm-5,div.col-sm-6,div.col-sm-7,div.col-sm-8,div.col-sm-9,
div.col-xs-1,div.col-xs-10,div.col-xs-11,div.col-xs-12,div.col-xs-2,div.col-xs-3,div.col-xs-4,div.col-xs-5,div.col-xs-6,div.col-xs-7,div.col-xs-8,div.col-xs-9 {
  padding-left: 0;
  padding-right: 0;
}

Button{
  border: none !important;
}

Button:focus{
  outline: none !important;
  box-shadow: none !important;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
