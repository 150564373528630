.footerdiv {
    margin-top: auto;
}

.footerdiv-row{
    display: flex;
    align-items: center;
}

.footer-left p{
    font-size: 15px;
    margin-left:20px;
    margin-bottom:0px;
    color: black;
    text-decoration: none;
}

.footer-mid p{
    font-size: 15px;
    color: black;
    margin: 10px;
}

.footer-left a:hover{
    color: rgb(20, 20, 20);
}

@media only screen and (max-width: 576px) {
    .footer-left p{
        font-size: 15px;    
        margin-bottom:0px;
        margin-left: 10px;   
        color: black;
        text-decoration: none;
    }
}
