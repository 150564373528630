.search-container {
  position: relative;
  width: 100%;
  padding: 0 10px;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(239, 237, 237);
  border-radius: 10px;
  padding: 8px 12px;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.search-input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  font-size: 14px;
}

.search-input::placeholder {
  color: #757575;
}

.dataResult {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 5px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.dataResult a {
  display: block;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;
}

.dataResult a:hover {
  background-color: #f5f5f5;
}

.dataResult p {
  margin: 0;
  font-size: 14px;
}