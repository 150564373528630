.homeCarousel.right.carousel-control, .homeCarousel.left.carousel-control {
    filter: none !important;
}

.carouselFirst h1{
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
}

.homeCarousel Button {
    background-color: #4D4D4D;
    width: 150px;
    height: 45px;
}

.homeCarousel Button:hover {
    background-color: #4D4D4D;
}

.carousel-item {
    height: 650px;
}

.carousel-item h1{
    margin-top: 100px;
}

.addressHeader{
    padding: 0px 0px 30px 0px;
}

.locationDiv h4{
    margin-top: 30px;
    letter-spacing: 0.015em;
    line-height: 1.3em;
}

.slick-prev:before, .slick-next:before {
    font-size: 20px;
    color: black !important;
}

.carousel-indicators { 
    visibility: hidden !important; 
}

.mapsContainer iframe{
    width: 90%;
    height: 380px;
}

.responsive-image {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 576px) {
    
    .carouselFirst h1{
        font-size: 27px;
        max-width: 70%;
        margin-right: auto;
        margin-left: auto;
    }

    .carousel-item {
        height: 250px;
    }

    .locationDiv h4{
        font-size: 16px;
    }

    .mapsContainer iframe{
        width: 100%;
        height: 380px;
    }

    .locationDiv h4{
        margin-top: 20px;
        text-align: center;
    }
}
