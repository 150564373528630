.item-name {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media print {
    #root, .app-div, body {
        all: unset!important;
    }
    
    .receipt {
        font-family: monospace;
        padding: 0;
    }
}

.receipt {
    font-family: monospace;
    padding: 10px;
}

.receipt header {
    text-align: center;
    margin-bottom: 8px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0!important;
    font-weight: bold!important;
    line-height: 1!important;
}
.receipt footer {
    text-align: center;
}
.mytable{
    border-bottom: 1px dashed black;
    border-top: 1px dashed black;
}
