.card {
  border-radius: 0px !important;
  border-color: #e1e7ec
}

.mb-30 {
  margin-bottom: 30px !important
}

.card-img-tiles {
  position: relative;
  display: block;
  margin: 0px;
  height: 0px;
  padding-bottom: 100%;
  overflow: hidden;
  border-bottom: 1px solid lightgray;
}

a {
  color: #0da9ef;
  text-decoration: none !important
}

.card-img-tiles>.inner {
  display: table;
  width: 100%;
}

.card-img-tiles .main-img,
.card-img-tiles .thumblist {
  width: 100%;
  vertical-align: middle;
}

.card-img-tiles .main-img>img:last-child,
.card-img-tiles .thumblist>img:last-child {
  margin-bottom: 0
}

.card-img-tiles .main-img>img,
.card-img-tiles .thumblist>img {
  display: block;
  width: 100%;
  margin-bottom: 6px
}

.thumblist {
  width: 35%;
  border-left: 1px solid #e1e7ec !important;
  display: table-cell;
  width: 65%;
  padding: 15px;
  vertical-align: middle
}

.card-img-tiles .thumblist>img {
  display: block;
  width: 100%;
  margin-bottom: 6px
}

.btn-group-sm>.btn,
.btn-sm {
  padding: .45rem .5rem !important;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.slick-slide > div, .slick-list, .slick-track {
  height: 100%;
}

.slick-slide {
  height: auto!important;
}

.slick-track {
  display: flex!important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}