.card-product{
    color: black;
    text-decoration: none !important;

    height: 100%;
    display: block;
}

.productlist img{
    width: 80%;
    height: auto;
}

.card-product:hover{
    color:black;
}

.card-body{
    padding: 0px 0px !important;
}

.card-title{
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.card-text{
    padding-top: 7px;
    padding-bottom: 25px;
}

.page-link{
    color: black !important; 
}

.page-item.active .page-link {
    color: black !important;
    border-color: black !important;
    background-color: white !important;
  }

@media only screen and (max-width: 576px) {
    .productlist img{
        width: 65%;
    }
}
