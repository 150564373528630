.cartAddedButton{
    background-color: white !important;
    border: 1px solid black !important;
    color: black !important;
}

.cartAddedButton:hover{
    background-color: #F2F2F2 !important;
    border: 1px solid black !important;
}

.product-pointer{
    max-width:fit-content;
    background-color: #C4C4C4;;
    padding: 15px 10px 15px 10px;
}

.productdisplay{
    padding-top: 25px;
}

.productbranding h5{
    margin-top: 5px;
}

.productbranding h1{
    max-width: 85%;
    margin-top: 15px;
}

.productbranding h2{
    margin: 10px 0px;
}

.productbranding p{
    margin:0px;
    max-width: 85%;
}

.productspec p{
    margin-left: 100px;
}

.productavailable{
    color: green !important;
    font-Size: 16px;
    text-transform: uppercase;
}

.productempty{
    color: red !important;
    font-Size: 16px;
    text-transform: uppercase;
}

.addtocart{
    background-color: #dbdbdb !important;
    border:none !important;
    color: black !important;
    height: 50px;
}

.addtocart img{
    width:25px;
    height:25px;
    margin-right:7px;
}
.addtocart:hover{
    background-color: #cac9c9 !important;
    border:none !important;
    color: black !important;
}

.detailedImage {
    width: 410px;
}

.accordion-item{
    max-width:90%;
    margin: auto;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.accordion-body{
    text-align: justify;
    max-width: 99%;
    white-space: p
    re-wrap;
}

.updateQuantityContainer{
    max-width: 25%;
    border: 2px solid black;
    height: 38px;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 650px) {
    .detailedImage {
        width: 270px;
    }

    .productbranding{
        max-width: 77% !important;
        margin: auto;
    }

    .productbranding h1{
        font-size: 23px;
        max-width: 100%;
    }

    .productbranding h2{
        font-size: 19px;
    }

    .productbranding h5{
        font-size: 16px;
    }

    .productbranding h6{
        font-size: 14px;
    }

    .productbranding p{
        font-size: 14px;
        max-width: 100%;
    }

    .productspec p{
        font-size: 13px;
        margin-left: 15px;
    }

    .accordion-body{
        margin-top: 15px;
    }

    .updateQuantityContainer{
        max-width: 55%;
    }
}

@media (min-width: 990px) and (max-width: 1350px) {
    .updateQuantityContainer{
        max-width: 35%;
    }
}

.ellipsis-1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.fs-xs {
    font-size: 0.825rem;
}
