.stepContainer{
    max-width: 85%;
}

.steplist a {
    color: black !important;
    text-decoration: none !important;
}

.steplist {
    padding-left: 16px;
}

.steplist a:hover {
    text-decoration: underline !important;
}

.stepContainer p{
    text-align: justify;
}

.stepButton{
    font-size: 20px;
    letter-spacing: 0.025em;
    width: 180px;
    height: 45px;
    border-radius: 7px;
    background-color: #414141 !important;
    color: white !important;
    font-weight: 600;
    border: none;
}

@media screen and (max-width: 560px) {
    .steplist li {
        font-size: 14px;
    }

    .stepContainer p{
        font-size: 15px;
        line-height: 1.6em;
    }
}