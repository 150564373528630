.app-div {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.categorylist img{
    width: 80%;
    height: auto;
} 

@media only screen and (max-width: 576px) {
    .categorylist img{
        width: 70%;
    } 
}
